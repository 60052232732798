import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/instagram-photo-contest.png';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';

// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Instagramハッシュタグフォトコンテスト"
		  desc="このサンプルはInstagramでフォトコンテストを行う方法です。フォロコンテストの写真をオリジナルハッシュタグを付けて、Instagram投稿して参加する方法です。リタッチのUGCギャラリーで自動収集してフォトコンテストを開催できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/instagram/follow-hashtag'}
		  type={'Article'}
		  shortName="Instagramaハッシュタグフォトコンテスト"
		  createdAt="2021-12-01"
		/>
	  <Navbar />
	  <ContentBanner
		homePageText="Home"
		homePageUrl="/"
		activePageText="Instagramハッシュタグフォトコンテスト"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/instagram',
		  name: 'Instagramフォトコンテスト'
		}]}
	  />
	  <ExampleDetail
		title={"Instagramハッシュタグフォトコンテスト"}
		description={<>このサンプルはInstagramでフォトコンテストを行う方法です。<br/>フォトコンテストの写真をオリジナルハッシュタグを付けて、Instagram投稿して参加する方法です。リタッチのUGCギャラリーで自動収集してフォトコンテストを開催できます。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.UGC, Constants.CAMPAIGN_EFFECTS.INCREASED_SALES,Constants.CAMPAIGN_EFFECTS.INCREASED_ENGAGEMENT]}
		plan={'ベーシックプラン以上'}
		galleryId="44wfxikmbxr5hm"
		platformExample={
		  <PlatformExample
			title="その他のInstagramフォトコンテスト"
			types={['instagramFollowLike', 'instagramFollowRepost', 'instagramFollowHashtag', 'password', 'instagramShare']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples